import { RunAssignVehicleToDriverInput, Vehicle } from '@/models/gen/graphql';
import { handleError, parsePhoneNumber, titleCase } from '@/utils';
import { useCallback, useState } from 'react';

import { AssignVehicleDropdown } from '@/components/VehicleDropdown';
import { Button } from 'react-bootstrap';
import React from 'react';
import Tooltip from '@/features/Trips/components/Tooltip';
import assignVehicleToDriverBulk from '@/api/services/vehicles/assignVehicleToDriver';

type AssignVehicleCellProps = {
  actual: string;
  scheduled: string;
  airportCode: string;
  driverId: string;
  vehicle: Vehicle;
  refetch: () => void;
  rowId: string;
};
const AssignVehicleCell = ({
  actual,
  scheduled,
  airportCode,
  driverId,
  vehicle,
  refetch,
  rowId,
}: AssignVehicleCellProps): React.JSX.Element => {
  const [value, setValue] = useState(vehicle?.id || '');
  const [editing, setEditing] = useState(false);

  const handleChange = useCallback(
    async (value: string): Promise<void> => {
      try {
        if (!value || value === vehicle?.id) return;
        if (!rowId) throw new Error('No trip found.');
        setValue(value);
        const input: RunAssignVehicleToDriverInput = {
          userId: driverId || '',
          vehicleId: value,
          tripId: rowId || null,
          scheduled: scheduled || null,
        };
        await assignVehicleToDriverBulk(input);
        refetch();
      } catch (err) {
        handleError(err, { notification: { title: 'Assign Vehicle To Trip' } });
        setValue(vehicle?.id || '');
      }
    },
    [vehicle?.id, rowId, driverId, scheduled, refetch]
  );
  return (
    <div className="w-100">
      {editing && (
        <AssignVehicleDropdown
          name="vehicle"
          value={value}
          className="DropdownCell {padding-left:0;padding-right:0!;text-align:center;}>input"
          onChange={(val) => {
            handleChange(val);
            setEditing(false);
          }}
          onBlur={() => setEditing(false)}
          airportCode={airportCode}
          scheduled={scheduled}
          actual={actual}
          details={{
            id: vehicle?.id,
            trackingId: vehicle?.trackingId,
          }}
          options={{
            onToggle: setEditing,
            lazyLoadItems: true,
            openOnMount: true,
            showLoadingSpinner: false,
            showRefreshButton: false,
            showChevron: false,
            showClearButton: false,
            autoSelect: false,
            disabled: !driverId,
            locale: { 'Select...': vehicle?.trackingId || '--' },
          }}
        />
      )}
      {!editing && (
        <Tooltip
          className="w-100 h-100"
          content={
            vehicle && (
              <>
                <div className="text-center">{`Vehicle #${vehicle?.trackingId || '--'}`}</div>
                <div>{`Phone: ${parsePhoneNumber(vehicle?.phoneNumber || '', ' ')}`}</div>
                <div className="text-center">{`Capacity: ${vehicle?.capacity || '--'}`}</div>
                <div className="text-center">{titleCase(`${vehicle?.make || ''} ${vehicle?.model || ''}`)}</div>
              </>
            )
          }
        >
          <Button
            variant="icon"
            className="w-100 p-0 text-center"
            onClick={() => {
              if (driverId) setEditing(true);
            }}
          >
            {vehicle?.trackingId || '--'}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default React.memo(AssignVehicleCell);
