import {
  RunAssignDriverInput,
  RunAttemptAssignDriverAndCombineTripsInput,
  RunGetDriverEstimatedDriveTimeInput,
  Trip,
  TripAndCombineType,
  TripCombineTypeEnum,
  User,
} from '@/models/gen/graphql';
import { TripRowActionEnum, TripTableState } from '@/features/Trips/components/TripsTable/hook';
import { handleError, titleCase } from '@/utils';
import { useCallback, useState } from 'react';

import { AssignDriverDropdown } from '@/components/DriverDropdown';
import { Button } from 'react-bootstrap';
import { Confirmation } from '@/hooks/useConfirmation';
import HasPermission from '@/components/HasPermission';
import { ILLEGAL_COMBINES } from '@/constants';
import ImageDisplay from '@/components/ImageDisplay';
import LoadingSpinner from '@/components/LoadingSpinner';
import React from 'react';
import Tooltip from '@/features/Trips/components/Tooltip';
import { formatTripTitle } from '@/features/Trips/components/TripsTable/utils';
import { getDriverById } from '@/api/services/users/searchUsers';
import runAssignDriver from '@/api/services/trips/runAssignDriver';
import runAttemptAssignDriverAndCombineTrips from '@/api/services/trips/runAttemptAssignDriverAndCombineTrips';
import runGetDriverEstimatedDriveTime from '@/api/services/trips/runGetDriverEstimatedDriveTime';
import runUnassignDriver from '@/api/services/trips/runUnassignDriver';

type AssignDriverCellProps = {
  rowId: string;
  title: string;
  airportCode: string;
  actual: string;
  driver: User;
  providerId: string;
  puLocationId: string;
  doLocationId: string;
  combineId: string;
  combineType: TripCombineTypeEnum;
  scheduled: string;
  pilots: number;
  attendants: number;
  onSetRow: TripTableState['onSetRow'];
  confirmIllegalCombines: Confirmation.Method<{ title: string }>;
};

const AssignDriverCell = ({
  rowId,
  title,
  airportCode,
  actual,
  driver,
  providerId,
  puLocationId,
  doLocationId,
  combineType,
  scheduled,
  pilots,
  attendants,
  onSetRow,
  confirmIllegalCombines,
}: AssignDriverCellProps): React.JSX.Element => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>(driver?.id || '');
  const [driveTimeWarning, setDriveTimeWarning] = useState<string>('');
  const hasWarning = !!driveTimeWarning || ILLEGAL_COMBINES.includes(combineType);

  const handleChange = useCallback(
    async (input: string): Promise<void> => {
      try {
        const result: Pick<Trip, 'id' | 'driverId' | 'driver' | 'vehicle' | 'vehicleId' | 'combineType'> = {
          id: rowId,
          driverId: input,
          driver: undefined,
          vehicle: undefined,
          vehicleId: undefined,
          combineType: combineType ?? null,
        };
        let combineTripIds: string[];
        setLoading(true);
        if (!!input && input !== driver?.id) {
          setValue(input);

          if (HasPermission.check('allowAssignAndAutoCombine')) {
            const runAttemptAssignDriverAndCombineTripsInput: RunAttemptAssignDriverAndCombineTripsInput = {
              tripId: rowId,
              driverId: input,
              providerId,
              scheduled,
              pilots,
              attendants,
              puLocationId,
              doLocationId,
            };

            // assign driver with illegal combines flow
            try {
              const res = await runAttemptAssignDriverAndCombineTrips(runAttemptAssignDriverAndCombineTripsInput);
              result.vehicle = res?.vehicle || null;
              result.vehicleId = res?.vehicle?.id || null;
              // if there were any trips that were auto combined with no illegal combines error
              if (res?.output?.length) {
                const tripIds = res.output
                  .flatMap((combine: TripAndCombineType): Trip[] => combine.trips || [])
                  .map(({ id }: Trip): string => id);
                combineTripIds = tripIds;
                result.combineType = res?.appliedCombineType || null;
              }
            } catch (err) {
              // custom illegal combines error
              const illegalCombines: TripAndCombineType[] = err?.illegalCombines || [];
              const combineType: TripCombineTypeEnum = err?.combineType || null;

              if (!illegalCombines?.length) throw err; // this is a gql error
              const trips = illegalCombines?.flatMap((combine: TripAndCombineType): Trip[] => combine.trips || []);
              await confirmIllegalCombines(
                {
                  Body: {
                    as: () => <IllegalCombineMessage trips={trips} />,
                  },
                },
                { title }
              );
              const tripIds = trips.map(({ id }: Trip): string => id);
              const runAssignDriverWithCombinesInput: RunAssignDriverInput = {
                tripId: rowId,
                driverId: input,
                combines: [{ tripIds, combineType }],
                scheduled,
              };
              const res = await runAssignDriver(runAssignDriverWithCombinesInput);
              result.vehicle = res?.vehicle || null;
              result.vehicleId = res?.vehicle?.id || null;
              result.combineType = combineType;
              combineTripIds = tripIds;
            }
          } else {
            // assign driver flow
            const runAssignDriverInput: RunAssignDriverInput = {
              tripId: rowId,
              driverId: input,
              scheduled,
            };
            const res = await runAssignDriver(runAssignDriverInput);
            result.vehicle = res?.vehicle || null;
            result.vehicleId = res?.vehicle?.id || null;
          }

          const driver = await getDriverById(input);
          result.driver = driver;
          if (!driver) throw new Error('Failed to get driver details. Try again.');

          const allowGetDriverEstimatedDriveTime = HasPermission.check('allowGetDriverEstimatedDriveTime');
          setLoading(allowGetDriverEstimatedDriveTime);

          if (HasPermission.check('allowGetDriverEstimatedDriveTime')) {
            // bad scheduling flow
            const runGetDriverEstimatedDriveTimeInput: RunGetDriverEstimatedDriveTimeInput = {
              scheduled,
              toLocationId: puLocationId,
              tripId: rowId,
              driverId: input,
            };
            runGetDriverEstimatedDriveTime(runGetDriverEstimatedDriveTimeInput)
              .then((res) => {
                if (!res?.warning) return res;
                setDriveTimeWarning(res.warning);
              })
              .catch((err) => {
                handleError(err, { notification: { title: 'Get Driver Estimated Drive Time' } });
              })
              .finally(() => {
                setLoading(false);
              });
          }
          onSetRow(result, combineTripIds, TripRowActionEnum.UPDATE_AND_COMBINE);
        } else if (input) {
          await runUnassignDriver(rowId);

          const update = {
            driverId: null,
            driver: null,
            vehicleId: null,
            vehicle: null,
            combineType: null,
            combineId: null,
            id: rowId,
          };

          onSetRow(update, null);
          setValue('');
          setLoading(false);
          setDriveTimeWarning('');
        }
      } catch (err) {
        setValue(driver?.id);
        handleError(err, {
          notification: { title: !!input && input !== driver?.id ? 'Assign Driver' : 'Unassign Driver' },
        });
      } finally {
        setLoading(false);
      }
    },
    [
      rowId,
      title,
      airportCode,
      actual,
      scheduled,
      driver,
      providerId,
      puLocationId,
      doLocationId,
      combineType,
      onSetRow,
      confirmIllegalCombines,
    ]
  );

  return (
    <>
      <div className={`h-100 ${!loading ? 'w-100' : ''}`}>
        {editing && (
          <AssignDriverDropdown
            name="driver"
            className="DropdownCell h-100 {padding-left:0;padding-right:0!;text-align:left;}>input"
            value={value || ''}
            airportCode={airportCode}
            scheduled={scheduled}
            actual={actual}
            details={driver}
            onChange={(val) => {
              handleChange(val);
              setEditing(false);
            }}
            autoFocus
            options={{
              onToggle: setEditing,
              loading,
              showLoadingSpinner: false,
              disabled: loading,
              lazyLoadItems: true,
              openOnMount: true,
              showRefreshButton: false,
              showChevron: false,
              showClearButton: false,
              locale: { 'Select...': driver?.fullName || '--' },
              autoSelect: false,
            }}
          />
        )}
        {!editing && (
          <Tooltip
            className="w-100 h-100"
            placement="left"
            content={
              driver && (
                <div className="d-flex">
                  <ImageDisplay className="{width:50px!;height:50px!;margin-right:.7rem;}" src={driver?.avatar} />
                  <div>
                    <div>{driver?.fullName || '--'}</div>
                    <div>{driver?.employeeId || '--'}</div>
                    <div>{(driver?.cityName || '--').toUpperCase()}</div>
                  </div>
                </div>
              )
            }
          >
            <Button variant="icon" className="w-100 h-100 p-0 {text-align:left!;}" onClick={(): void => setEditing(true)}>
              {(driver?.fullName && `${driver?.employeeId || ''} ${driver?.fullName}`) || (loading ? '' : '--')}
            </Button>
          </Tooltip>
        )}
      </div>
      {loading && (
        <span className="ms-1">
          <LoadingSpinner size="sm" />
        </span>
      )}
      {!loading && hasWarning && (
        <Tooltip
          content={
            <div className="d-flex flex-column gap-1 p-2 text-center">
              <div className="text-danger fs-6">WARNING:</div>
              <div>{ILLEGAL_COMBINES.includes(combineType) && 'This combine is against contract and policy!'}</div>
              <div className="fw-bold">{!!driveTimeWarning && titleCase(driveTimeWarning)}</div>
            </div>
          }
        >
          <i className="fa fa-warning text-danger px-1" />
        </Tooltip>
      )}
    </>
  );
};

const IllegalCombineMessage = (props: { trips: Trip[] }): React.JSX.Element => (
  <>
    <div>This combine is against contract and policy! Driver already has the following pickups:</div>
    <div className="overflow-auto pt-2 {max-height:15rem}">
      {props.trips.map(
        (trip: Trip, index: number): React.JSX.Element => (
          <div className="mb-2" key={index}>
            {trip?.puLocation?.name} -
            <strong className="fs-5">{formatTripTitle(trip.servicerIataAirlineCode, trip.flightNumber, trip.scheduled)}</strong>
          </div>
        )
      )}
    </div>
  </>
);

export default React.memo(AssignDriverCell);
