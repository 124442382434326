import { Alias, Completion, Location, TrackFlightTypeEnum, Trip } from '@/models/gen/graphql';
import { Datetime, generateDirectionsLink, getClasses, zeroPadFlightNumber } from '@/utils';
import React, { useCallback } from 'react';
import { formatTripTitle, isUpcomingTrip } from '@/features/Trips/components/TripsTable/utils';
import useTripSettings, { ColumnKeyEnum } from '@/features/Trips/components/TripSettingsModal/hook';

import ActivityCell from '@/features/Trips/components/TripsTable/Cells/ActivityCell';
import ActualCell from '@/features/Trips/components/TripsTable/Cells/ActualTimeCell';
import AssignDriverCell from '@/features/Trips/components/TripsTable/Cells/AssignDriverCell';
import AssignVehicleCell from '@/features/Trips/components/TripsTable/Cells/AssignVehicleCell';
import CombineCell from '@/features/Trips/components/TripsTable/Cells/CombineCell';
import CompletionCell from '@/features/Trips/components/TripsTable/Cells/CompletionCell';
import { Confirmation } from '@/hooks/useConfirmation';
import { DATE_FE_FORMAT_SHORT } from '@/constants';
import FcrCell from '@/features/Trips/components/TripsTable/Cells/FcrCell';
import FlagAndCommunicationCell from '@/features/Trips/components/TripsTable/Cells/FlagAndCommunicationCell';
import LocationCell from '@/features/Trips/components/TripsTable/Cells/LocationCell';
import RateCell from '@/features/Trips/components/TripsTable/Cells/RateCell';
import Tooltip from '@/features/Trips/components/Tooltip';
import useTripTableState from '@/features/Trips/components/TripsTable/hook';

export type TripsTableRowEventHandlers = {
  onEditTrip: (data: Partial<Trip> | string, edit?: boolean) => void;
  onEditLocation: (data: Location) => void;
  onEditFlag: (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string) => void;
  onEditCommunication: (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string, offset: string) => void;
  onEditCompletion: (id: string, servicerIataAirlineCode: string, flightNumber: number, scheduled: string, completion: Completion) => void;
  onEditActivity: (data: Partial<Trip> | string) => void;
  onEditFcr: (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string) => void;
  onEditCombine: (id: string, servicerIataAirlineCode: string, flightNumber: number, scheduled: string, combineId: string) => void;
  onEditRateReport: (selected: string[]) => void;
  onEditRate: (tripId?: string, rateGroupId?: string) => void;
  confirmIllegalCombines: Confirmation.Method<{ title: string }>;
};
type TripsTableRowProps = TripsTableRowEventHandlers & {
  rowId: string;
  index: number;
  style?: React.CSSProperties;
};
const TripsTableRow = (
  {
    rowId,
    index,
    onEditTrip,
    onEditLocation,
    onEditFlag,
    onEditCommunication,
    onEditCompletion,
    onEditActivity,
    onEditFcr,
    onEditCombine,
    onEditRateReport,
    onEditRate,
    confirmIllegalCombines,
    style,
    ...props
  }: TripsTableRowProps,
  ref: React.ForwardedRef<HTMLTableRowElement>
): React.JSX.Element => {
  const columns = useTripSettings(({ state }) => state.columns);
  const data = useTripTableState(({ state }) => state.trips.get(rowId) || ({} as Trip));
  const isSelected = useTripTableState(({ state }) => state.selected.get(rowId));
  const onSelect = useTripTableState(({ state }) => state.onSelect);
  const onSelectOnly = useTripTableState(({ state }) => state.onSelectOnly);
  const onSetRow = useTripTableState(({ state }) => state.onSetRow);
  const refetch = useTripTableState(({ state }) => state.refetch);

  const scheduledDatetime = new Datetime(data.scheduled);
  const scheduledUtcDatetime = new Datetime(data.scheduledUtc);
  const flightNumber = zeroPadFlightNumber(data.flightNumber);

  const title = formatTripTitle(data.servicerIataAirlineCode, data.flightNumber, data.scheduled);

  const handleLocationCellDoubleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    const puStreet = `${data.puLocation?.name}, ${data.puLocation?.address}, ${data.puLocation?.cityName}, ${data.puLocation?.stateCode} ${data.puLocation?.zipCode}`;
    const doStreet = `${data.doLocation?.name}, ${data.doLocation?.address}, ${data.doLocation?.cityName}, ${data.doLocation?.stateCode} ${data.doLocation?.zipCode}`;
    const url = generateDirectionsLink(puStreet, doStreet);
    window.open(url, '_blank');
  };
  const handleLocationCellClick = useCallback(
    (location: Location) => (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!location) return console.error('handleLocationCellClick: no location for trip = ', data.id);
      onEditLocation(location);
      return;
    },
    [onEditLocation]
  );

  return (
    <div
      {...props}
      ref={ref}
      style={style}
      onDoubleClick={(): void => {
        onSelectOnly(rowId);
        onEditTrip(data);
      }}
      className={getClasses(
        'table-row',
        isSelected ? 'selected' : '',
        data.kind,
        data.type,
        data.status,
        data.isLate ? 'LATE' : '',
        isUpcomingTrip(scheduledUtcDatetime) ? 'UPCOMING' : '',
        data.state?.completion ? 'COMPLETED' : '',
        data.deletedAt ? 'DELETED' : '',
        data.curbsideAt ? 'CURBSIDE' : ''
      )}
    >
      <div className="table-cell w-xs-fixed d-print-none">
        <input type="checkbox" name={`row-select_${index}`} checked={!!isSelected} onChange={(): void => onSelect(rowId)} />
      </div>
      {columns.get(ColumnKeyEnum.FLAGS) && (
        <div className="table-cell w-sm-fixed d-print-none">
          <FlagAndCommunicationCell
            flags={data.flags}
            hasCommunication={!!(data.communicationCount ?? data.communications?.length)}
            onFlagClick={(): void => onEditFlag(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled)}
            onCommunicationClick={(): void =>
              onEditCommunication(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled, data.offset)
            }
          />
        </div>
      )}
      {columns.get(ColumnKeyEnum.TYPE) && <div className="table-cell w-md-fixed">{data.type || '--'}</div>}
      {columns.get(ColumnKeyEnum.SCHEDULED_DATE) && (
        <div className="table-cell w-sm-grow">{scheduledDatetime.format(DATE_FE_FORMAT_SHORT)}</div>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_TIME) && <div className="table-cell w-sm-grow">{scheduledDatetime.time || '--'}</div>}
      {columns.get(ColumnKeyEnum.ACTUAL) && (
        <div className="table-cell flex-column gap-1 w-sm-grow">
          <ActualCell
            actual={data.trackFlight?.actual}
            arrivalGate={data.trackFlight?.arrivalGate}
            arrivalTerminal={data.trackFlight?.arrivalTerminal}
            label={data.trackFlight?.label}
            kind={data.kind}
            scheduled={data.scheduled}
          />
        </div>
      )}
      {columns.get(ColumnKeyEnum.AIRPORT_CODE) && <div className="table-cell w-md-fixed">{data.airportCode || '--'}</div>}
      {columns.get(ColumnKeyEnum.IATA_CODE) && <div className="table-cell w-sm-fixed">{data.servicerIataAirlineCode || '--'}</div>}
      {columns.get(ColumnKeyEnum.FLIGHT_NUMBER) && (
        <div
          className={`table-cell ${data.trackFlight?.type === TrackFlightTypeEnum.International ? 'bg-purple bg-opacity-15 text-dark' : undefined}`}
        >
          {data.loopName || flightNumber}
        </div>
      )}
      {columns.get(ColumnKeyEnum.PILOT) && <div className="table-cell w-sm-fixed">{data.pilots || 0}</div>}
      {columns.get(ColumnKeyEnum.ATTENDANT) && <div className="table-cell w-sm-fixed">{data.attendants || 0}</div>}
      {columns.get(ColumnKeyEnum.DRIVER) && (
        <div className={`table-cell w-md-grow ${!data.driver ? 'UNASSIGNED' : ''}`}>
          <AssignDriverCell
            rowId={data.id}
            title={title}
            airportCode={data.airportCode}
            actual={data.trackFlight?.actual}
            driver={data.driver}
            providerId={data.providerId}
            puLocationId={data.puLocationId}
            doLocationId={data.doLocationId}
            combineId={data.combineId}
            combineType={data.combineType}
            scheduled={data.scheduled}
            pilots={data.pilots}
            attendants={data.attendants}
            onSetRow={onSetRow}
            confirmIllegalCombines={confirmIllegalCombines}
          />
        </div>
      )}
      {columns.get(ColumnKeyEnum.VEHICLE) && (
        <div className="table-cell">
          <AssignVehicleCell
            actual={data.trackFlight?.actual}
            scheduled={data.scheduled}
            airportCode={data.airportCode}
            driverId={data.driverId}
            vehicle={data.vehicle}
            refetch={refetch}
            rowId={data.id}
          />
        </div>
      )}
      {columns.get(ColumnKeyEnum.PU_LOCATION) && (
        <div className="table-cell w-lg-grow" onDoubleClick={handleLocationCellDoubleClick}>
          <LocationCell
            name={data.puLocation?.name}
            address={data.puLocation?.address}
            stateCode={data.puLocation?.stateCode}
            zipCode={data.puLocation?.zipCode}
            cityName={data.puLocation?.cityName}
            id={data.puLocationId}
            onClick={handleLocationCellClick(data.puLocation)}
          />
        </div>
      )}
      {columns.get(ColumnKeyEnum.DO_LOCATION) && (
        <div className="table-cell w-lg-grow" onDoubleClick={handleLocationCellDoubleClick}>
          <LocationCell
            name={data.doLocation?.name}
            address={data.doLocation?.address}
            stateCode={data.doLocation?.stateCode}
            zipCode={data.doLocation?.zipCode}
            cityName={data.doLocation?.cityName}
            id={data.doLocationId}
            onClick={handleLocationCellClick(data.doLocation)}
          />
        </div>
      )}
      {columns.get(ColumnKeyEnum.CLIENT) && <div className="table-cell">{data.payerProvider?.displayName || '--'}</div>}
      {columns.get(ColumnKeyEnum.COMPLETION) && (
        <div className="table-cell w-sm-fixed d-print-none">
          <CompletionCell
            datetimeOfChange={data.state?.datetimeOfChange}
            displayName={data.state?.displayName}
            displayNameShort={data.state?.displayNameShort}
            onClick={(): void =>
              onEditCompletion(data?.id, data?.servicerIataAirlineCode, data?.flightNumber, data?.scheduled, data?.state?.completion)
            }
          />
        </div>
      )}
      {columns.get(ColumnKeyEnum.ACTIVITY) && (
        <div className="table-cell w-sm-fixed d-print-none">
          <ActivityCell onClick={(): void => onEditActivity(data)} />
        </div>
      )}
      {columns.get(ColumnKeyEnum.FCR) && (
        <div className="table-cell w-sm-fixed d-print-none">
          <FcrCell
            count={data.fcrCount ?? data.fcrs?.length}
            onClick={(): void => onEditFcr(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled)}
          />
        </div>
      )}
      {columns.get(ColumnKeyEnum.COMBINE) && (
        <div className="table-cell w-sm-fixed d-print-none">
          {(data.stopCount ?? data.stops?.length) > 0 && (
            <Tooltip content="Loop">
              <i className="sv sv-sync fs-5 text-cyan {text-shadow:-0.02rem|0|var(--bs-cyan),|0|0.02rem|var(--bs-cyan),|0.02rem|0|var(--bs-cyan),|0|-0.02rem|var(--bs-cyan);}" />
            </Tooltip>
          )}
          {!(data.stopCount ?? data.stops?.length) && (
            <CombineCell
              combineId={data.combineId}
              combineType={data.combineType}
              onClick={(): void => onEditCombine(data.id, data.servicerIataAirlineCode, data.flightNumber, data.scheduled, data.combineId)}
            />
          )}
        </div>
      )}
      {columns.get(ColumnKeyEnum.RATE) && (
        <div
          className="table-cell w-sm-grow"
          onDoubleClick={(event): void => {
            event.stopPropagation();
            onSelectOnly(rowId);
            onEditRate();
          }}
        >
          <RateCell rate={data.rate?.rate} rowId={data.id} onSetRow={onSetRow} onEditRateReport={onEditRateReport} />
        </div>
      )}
    </div>
  );
};

export default React.memo(React.forwardRef(TripsTableRow));
