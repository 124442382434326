import { Datetime, QueryInputType, queryInput, saveFile } from '@/utils';
import { Import, ImportStatusEnum, ImportTypeEnum, SortDirectionEnum } from '@/models/gen/graphql';
import { MONTH_END, MONTH_START } from '@/constants';
import React, { useRef, useState } from 'react';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';

import { ConnectionDetails } from '@/utils/custom';
import Filters from '@/components/Filters';
import FormField from '../../components/FormField';
import PageInfo from '@/components/PageInfo';
import PromiseButton from 'components/PromiseButton';
import { SEARCH_IMPORTS_PAGE_SIZE } from '@/api/services/imports/searchImports';
import Select from '@/components/Select';
import SelectAirportGroup from '@/components/SelectAirportGroup';
import SelectFromEnum from '@/components/SelectFromEnum';
import SelectImportProvider from '../../components/SelectImportProvider';
import SelectUser from '@/components/SelectUser';
import { Switch } from 'antd';
import { getClasses } from '@/utils/strings';
import runDownloadImports from '@/api/services/imports/runDownloadImports';
import runRollbackImports from '@/api/services/imports/runRollbackImports';
import { stringify } from '@/utils/objects';
import { useSearchImportsWithAirport } from '@/api/services/imports/searchImportsWithAirport';
import { useSearchParams } from 'react-router-dom';

const initImportHistoryTableState = {
  selected: [],
  sorting: {
    column: undefined,
    direction: undefined,
  },
};
const initFiltersForm = {
  airportCode: [],
  providerId: '',
  from: MONTH_START,
  to: MONTH_END,
  createdBy: '',
  status: '',
  type: 'MANIFEST',
  archived: 'false',
};
const ImportHistoryTable = (): JSX.Element => {
  const [state, setState] = useState(initImportHistoryTableState);
  const { selected, sorting } = state;

  const [{ data, loading }, { fetch: searchImports, refetch: refetchImports, fetchMore }] = useSearchImportsWithAirport();
  const { rows = [], hasNextPage = false, totalCount = 0 } = data || {};

  const [queryString] = useSearchParams();
  const { onSelect, makeSortable, filteredRows } = useVirtualTable(setState, { selected, sorting, rows });

  const lastQuery: any = useRef();
  const lastFilters: any = useRef();

  const downloadManifest = async (id: string, name?: string): Promise<void> => {
    const downloadResult = await runDownloadImports(id);
    const downloadURL = downloadResult.length === 1 ? downloadResult[0].url : undefined;
    if (downloadURL) saveFile(downloadURL, name);
  };

  const runSearchImports = async (filters: any): Promise<void> => {
    const { from = MONTH_START, to = MONTH_END, createdBy, providerId, status, type, airportCode, archived } = filters;
    lastFilters.current = filters;
    const query = {
      id: queryInput(queryString.has('filter') ? [queryString.get('filter')] : null),
      updatedAt: queryInput.date([from || MONTH_START, to || MONTH_END], true, SortDirectionEnum.Desc, 0),
      createdBy: queryInput(createdBy || null),
      providerId: queryInput(providerId || null),
      status: queryInput(
        status || [ImportStatusEnum.Failed, ImportStatusEnum.Ingested, ImportStatusEnum.RolledBack, ImportStatusEnum.RollingBack]
      ),
      type: queryInput(type),
      archivedAt: queryInput([], archived === 'true' ? QueryInputType.ISNOTNULL : QueryInputType.ISNULL),
      airportCode: airportCode?.length ? queryInput(airportCode) : null,
    };
    const fn = stringify.compare(lastQuery.current, query) ? refetchImports : searchImports;
    await fn([query]);
    lastQuery.current = query;
  };
  const getMore = async (after: number): Promise<ConnectionDetails<Import>> => {
    if (loading || !hasNextPage) return;
    const result = await fetchMore([lastQuery.current], {
      page: Math.round(after / SEARCH_IMPORTS_PAGE_SIZE),
      merge: true,
    });
    return result;
  };

  return (
    <>
      <PageInfo>
        {filteredRows?.length || 0} / {totalCount || 0} Imports
      </PageInfo>
      <Filters
        name="importHistory"
        value={initFiltersForm}
        onSubmit={runSearchImports}
        submitOnMount
        primary={({ values, onChange }): JSX.Element => {
          const { from, to, createdBy, airportCode, providerId, status, type } = values;
          return (
            <>
              <FormField name="dateRange" type="daterange" value={[from, to]} onChange={onChange.dateRange('from', 'to')} condensed />
              <FormField
                name="createdBy"
                placeholder="Imported By"
                onChange={onChange}
                value={createdBy}
                options={{ input: { as: SelectUser } }}
                searchable
                condensed
              />
              <FormField
                name="airportCode"
                placeholder="Airport"
                onChange={onChange}
                value={airportCode}
                options={{ input: { as: SelectAirportGroup } }}
                searchable
                multiple
                condensed
              />
              <FormField
                name="providerId"
                placeholder="Airline"
                onChange={onChange}
                value={providerId}
                options={{ input: { as: SelectImportProvider } }}
                searchable
                condensed
              />
              <FormField
                name="status"
                placeholder="Status"
                onChange={onChange}
                value={status}
                options={{
                  input: {
                    as: (props: any): JSX.Element => (
                      <Select {...props}>
                        {Object.entries(ImportStatusEnum)
                          .filter(([, val]: [string, ImportStatusEnum]): boolean => val !== 'ARCHIVED')
                          .map(
                            ([key, val]: [string, ImportStatusEnum], s: number): JSX.Element => (
                              <option value={val} key={s}>
                                {key}
                              </option>
                            )
                          )}
                      </Select>
                    ),
                  },
                }}
                condensed
              />
              <FormField
                name="type"
                placeholder="Type"
                onChange={onChange}
                value={type}
                options={{
                  input: {
                    as: (props: any): JSX.Element => <SelectFromEnum {...props} source={ImportTypeEnum} />,
                  },
                }}
                condensed
              />
            </>
          );
        }}
        alternate={({ values, onChange }): JSX.Element => {
          const { archived } = values;
          return (
            <>
              <div className="d-flex justify-content-end">
                <div className="h-100 d-flex flex-column justify-content-center me-2">
                  <span>Archives:</span>
                </div>
                <div className="{line-height:3rem;}">
                  <Switch
                    checked={archived === 'true'}
                    onChange={(val: boolean): void => {
                      onChange({ target: { name: 'archived', value: val ? 'true' : 'false' } });
                      runSearchImports({ ...lastFilters.current, archived: val ? 'true' : 'false' });
                    }}
                  />
                </div>
              </div>
            </>
          );
        }}
      />
      <VirtualTable
        name="provider"
        dynamicRowHeight
        data={filteredRows}
        onLazyLoad={rows?.length < totalCount ? getMore : undefined}
        loading={loading}
        selected={selected}
        header={{
          updatedAt: 'Date',
          type: 'Type',
          name: 'Name',
          provider: { displayName: 'Provider' },
          filename: 'File Name',
          importedBy: 'Import By',
          trips: 'Total',
          created: 'Created',
          updated: 'Updated',
          deleted: 'Deleted',
          unchanged: 'Unchanged',
          actions: (
            <div className="d-flex">
              <div style={{ overflow: 'hidden', width: 'calc(100% / 2)' }}>Download</div>
              <div style={{ overflow: 'hidden', width: 'calc(100% / 2)' }}>Delete</div>
            </div>
          ),
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected?.length === context?.rows?.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
            onDoubleClick={
              !data?.rolledBackAt
                ? (): void => {
                    window.location.href = `/manifests/${data.id}${window.location.search}`;
                  }
                : undefined
            }
          >
            <SelectCell className="alternate" onClick={onSelect} disabled={!!data?.rolledBackAt} />
            <DynamicCell
              selector="updatedAt"
              placeholder="--"
              className="alternate text-center"
              width="calc((100% / 13) * 0.75)"
              sorting={makeSortable('updatedAt')}
              render={({ value }: { value: string }): string => new Datetime().setAsUTC(value).toLocaleDatetime().frontendDatetimeShort}
            />
            <DynamicCell
              selector="type"
              placeholder="--"
              className="text-center"
              width="calc((100% / 13) * 0.75)"
              sorting={makeSortable('type')}
            />
            <DynamicCell
              selector="name"
              placeholder="--"
              className="alternate text-center"
              width="calc(100% / 11)"
              render={({ data }): string => data?.name || data?.email?.from}
            />
            <DynamicCell
              selector="provider.displayName"
              placeholder="--"
              className="text-center"
              width="calc(100% / 13)"
              sorting={makeSortable('provider.displayName')}
            />
            <DynamicCell
              selector="filename"
              placeholder="--"
              className="alternate text-center"
              width="calc((100% / 13) * 2)"
              sorting={makeSortable('filename')}
              render={({ data }): string => data?.filename || data?.email?.subject}
            />
            <DynamicCell
              selector="importedBy"
              placeholder="--"
              className="text-center"
              width="calc(100% / 13)"
              sorting={makeSortable('importedBy')}
            />
            <DynamicCell
              selector="trips"
              placeholder="--"
              className="text-center"
              width="calc(100% / 14)"
              sorting={makeSortable('trips')}
              render={({ data }): string => data?.total}
            />
            <DynamicCell
              selector="created"
              placeholder="--"
              className="bg-success bg-opacity-25 text-black text-center"
              width="calc(100% / 14)"
              sorting={makeSortable('created')}
            />
            <DynamicCell
              selector="updated"
              placeholder="--"
              className="bg-warning bg-opacity-25 text-center"
              width="calc(100% / 14)"
              sorting={makeSortable('updated')}
            />
            <DynamicCell
              selector="deleted"
              placeholder="--"
              className="bg-danger bg-opacity-25 text-black text-center"
              width="calc(100% / 14)"
              sorting={makeSortable('deleted')}
            />
            <DynamicCell
              selector="unchanged"
              placeholder="--"
              className="alternate text-center"
              width="calc(100% / 14)"
              sorting={makeSortable('unchanged')}
            />

            <DynamicCell
              selector="actions"
              placeholder="--"
              className="text-center"
              width="calc(100% / 7)"
              render={({ data }): React.JSX.Element | string => (
                <div className="d-flex justify-content-center">
                  {!data?.rolledBackAt && (
                    <>
                      {/* hide download and delete button if the manifest has been rolledback */}
                      <div style={{ overflow: 'hidden', width: 'calc(100% / 2)' }}>
                        <PromiseButton
                          variant="transparent"
                          onClick={() => downloadManifest(data.id)}
                          icon={<i className="sv sv-download2 fs-3" />}
                          feedback="Download"
                        />
                      </div>
                      <div style={{ overflow: 'hidden', width: 'calc(100% / 2)' }}>
                        <PromiseButton.Confirmation
                          variant="transparent"
                          feedback="Delete"
                          options={{ confirmation: { Body: { message: 'Are you sure you want to delete Manifest ?' } } }}
                          icon={<i className="sv sv-cross-square fs-3 text-danger" />}
                          onClick={async (): Promise<void> => {
                            await runRollbackImports([data?.id]);
                            refetchImports();
                          }}
                        />
                      </div>
                    </>
                  )}
                  {!!data?.rolledBackAt && (
                    <div style={{ overflow: 'hidden' }}>
                      {/* display rolledback date and time and user if manifest has been rolledback */}
                      <span>Deleted by {data?.rolledBackUser?.fullName} On</span>
                      <br />
                      {new Datetime().setAsUTC(data?.rolledBackAt).toLocaleDatetime().format('MMMM DD, YYYY HH:mm')}
                    </div>
                  )}
                </div>
              )}
            />
          </VirtualTableRow>
        )}
      />
    </>
  );
};

export default ImportHistoryTable;
