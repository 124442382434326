import { Datetime } from '@/utils';
import Tooltip from '@/features/Trips/components/Tooltip';

const CompletionCell = ({
  datetimeOfChange,
  displayName,
  displayNameShort,
  onClick,
}: {
  datetimeOfChange: string;
  displayName: string;
  displayNameShort: string;
  onClick: () => void;
}): React.JSX.Element => {
  const datetimeOfChangeDatetime = datetimeOfChange ? new Datetime(datetimeOfChange) : null;
  return (
    <Tooltip content={displayName || 'No Completion'}>
      <button onClick={onClick}>
        {displayNameShort && <small className="completion-reason">{displayNameShort}</small>}
        {datetimeOfChangeDatetime && <small className="completion-time text-success d-block">{datetimeOfChangeDatetime.time}</small>}
        {!datetimeOfChangeDatetime && !displayNameShort && <i className="sv sv-time fs-5" />}
      </button>
    </Tooltip>
  );
};

export default CompletionCell;
